import FullscreenIcon from "@mui/icons-material/Fullscreen";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { PmmData } from "./PmmData";
import { plotPmm } from "./plotly-render";

type Props = {
  pmmData: PmmData;
};

function IsolatedPlot({ pmmData }: Props) {
  const plotId = "concrete-pmm-plot-fullscreen";

  useEffect(() => {
    plotPmm(pmmData, plotId, true);
  }, [pmmData]);
  return <div id={plotId} style={{ width: "100%", height: "70vh" }} />;
}

export default function PlotlyPmmDesigner({ pmmData }: Props) {
  const plotId = "concrete-pmm-plot";

  const [isFullscreenOpen, setFullscreenOpen] = useState(false);

  useEffect(() => {
    plotPmm(pmmData, plotId);
  }, [pmmData]);

  return (
    <>
      <Box
        component="div"
        width="100%"
        height={"100%"}
        position={"relative"}
        sx={{
          borderWidth: 2,
          borderRadius: 1,
          border: "solid",
          borderColor: "transparent",
          "&:hover": {
            borderColor: (t) => t.palette.grey[500],
          },
        }}
      >
        <div id={plotId} style={{ width: "100%", height: "100%" }} />
        <Typography variant="caption" textAlign={"center"} width={"100%"} display={"block"}>
          3D PMM Capacity Plot
        </Typography>
        <Tooltip title="Make 3D view fullscreen">
          <IconButton
            onClick={() => setFullscreenOpen(true)}
            sx={{ position: "absolute", top: "1.5rem", left: "0.75rem" }}
          >
            <FullscreenIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </Box>
      <Dialog
        open={isFullscreenOpen}
        onClose={() => setFullscreenOpen(false)}
        maxWidth={"lg"}
        fullWidth
      >
        <DialogTitle>3D PMM Capacity Plot</DialogTitle>
        <IsolatedPlot pmmData={pmmData} />
        <DialogActions>
          <Button onClick={() => setFullscreenOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
